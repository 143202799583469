import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
import { css } from "@emotion/react"

import { HomePageData } from "../types/pages/IndexPage"

import useSiteMetadata from "../queries/useSiteMetadata"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import BsCol, { ColProps } from "react-bootstrap/Col"
import ResponsiveEmbed from "react-bootstrap/ResponsiveEmbed"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import SectionTitle from "../components/SectionTitle"
import RecipesGrid from "../components/RecipesGrid"

import {
  BenefitsSection,
  LaPlantaSection,
  ProductsSection,
  BenefitsGrid,
  TraditionSection,
  LaPlantaGrid,
  RecipesSection,
  LaYucaSection,
} from "../components/HomePage"

import { mediaQueries } from "../styles"

import Banner from "../components/Banner"
import ContactSection from "../components/ContactSection"
import AmazonProduct from "../components/AmazonProduct"

interface Props {
  data: HomePageData
}

const IndexPage = ({ data }: Props) => {
  const { homePage } = useSiteMetadata()
  const { t } = useTranslation("", { keyPrefix: "home" })

  return (
    <Layout hasHero={homePage.hasHero} heroData={data.heroData}>
      <SEO page={homePage} />

      <ProductsSection
        id={t("productsSection.anchor")}
        style={{ background: "white" }}
      >
        <Container>
          <SectionTitle>{t("productsSection.title")}</SectionTitle>

          <Trans
            i18nKey="products.heading"
            components={{ bold: <strong />, heading: <h3 /> }}
          />

          <Row>
            {data.amazonProducts.nodes.map(product => (
              <Col key={product.id} md={6} lg={4} css={{ marginBottom: "1rem" }}>
                <AmazonProduct {...product} />
              </Col>
            ))}
          </Row>

          {/* <BuyNow /> */}
        </Container>
      </ProductsSection>

      <RecipesSection id={t("recipesSection.anchor")}>
        <Container>
          <SectionTitle>{t("recipesSection.title")}</SectionTitle>
          <Row className="justify-content-center">
            <Col className="col-xxl-9">
              <RecipesGrid recipes={data.recipes.nodes} />
            </Col>
          </Row>
        </Container>
      </RecipesSection>

      <Banner
        title={data.adBanner.text}
        buttonText={data.adBanner.buttonText}
        buttonLink={data.adBanner.buttonLink}
        background={data.adBanner.background.gatsbyImageData}
        backgroundMobile={data.adBanner.backgroundMobile.gatsbyImageData}
        css={css`
          margin-bottom: 0;
        `}
      />

      <TraditionSection id={t("traditionSection.anchor")}>
        {/* on xxl breakpoint objectFit: cover  */}
        <GatsbyImage
          image={data.traditionBg.childImageSharp.gatsbyImageData}
          className="background-image"
          style={{ position: `absolute` }}
          imgStyle={{ objectFit: "contain", objectPosition: "center bottom" }}
          alt={t("traditionSection.backgroundCaption")}
        />
        <Container>
          <SectionTitle>{t("traditionSection.title")}</SectionTitle>
          <Row
            className="align-items-xxl-start"
            css={css`
              margin-bottom: 2rem;
            `}
          >
            <Col sm={8}>
              <ResponsiveEmbed
                aspectRatio="16by9"
                css={css`
                  margin-bottom: 2rem;
                `}
              >
                <iframe
                  title="Casabi Una Gran Idea"
                  src={`//www.youtube.com/embed/${data.homepage.homepageVideo.providerUid}/?rel=0`}
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </ResponsiveEmbed>

              <Row>
                <Col xl={10}>
                  <Trans
                    i18nKey="home.traditionSection.panyucaTitle"
                    components={{ bold: <strong />, heading: <h2 /> }}
                  />
                  <p>{t("traditionSection.panyucaContent")}</p>
                </Col>
              </Row>

              <GatsbyImage
                image={data.manioc.childImageSharp.gatsbyImageData}
                css={css`
                  ${mediaQueries.min768} {
                    display: none;
                  }
                `}
                alt="Manioc"
              />
            </Col>
            <Col
              sm={4}
              className="order-first order-md-last"
              css={css`
                ${mediaQueries.max576} {
                  margin-bottom: 2rem;
                }
              `}
            >
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  margin-bottom: 1rem;
                `}
              >
                <h2
                  css={css`
                    max-width: 7ch;
                    margin-right: 1.5rem;
                    margin-bottom: 0;
                  `}
                >
                  {t("traditionSection.casabeTraditionTitle")}
                </h2>
                <GatsbyImage
                  image={data.panyucaSealMobile.childImageSharp.gatsbyImageData}
                  css={css`
                    flex: none;
                  `}
                  alt={t("traditionSection.casabeTraditionTitle")}
                />
              </div>
              <p
                css={css`
                  margin-bottom: 0;
                `}
              >
                {t("traditionSection.casabeTraditionContent")}
              </p>

              <GatsbyImage
                image={data.manioc.childImageSharp.gatsbyImageData}
                css={css`
                  margin-top: 6rem;
                  margin-right: auto;
                  margin-left: auto;

                  ${mediaQueries.max576} {
                    display: none;
                  }
                `}
                alt="Manihot esculenta"
              />
            </Col>
          </Row>
        </Container>
        <GatsbyImage
          image={data.traditionBgMobile.childImageSharp.gatsbyImageData}
          className="background-image-mobile"
          alt="Tradición"
        />
      </TraditionSection>

      <LaYucaSection id={t("cassavaSection.anchor")}>
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={6}>
              <GatsbyImage
                image={data.cassavaHorizontal.childImageSharp.gatsbyImageData}
                css={css`
                  margin-bottom: 2rem;

                  ${mediaQueries.min992} {
                    margin-bottom: 4rem;
                  }
                `}
                alt={t("cassavaSection.cassavaRootCaption")}
              />
            </Col>
            <div className="w-100"></div>
            <Col sm={6}>
              <Trans
                i18nKey="home.cassavaSection.title"
                components={{
                  bold: <strong />,
                  heading: (
                    <h2
                      css={css`
                        max-width: 27ch;
                      `}
                    />
                  ),
                  span: (
                    <span
                      css={css`
                        display: block;
                      `}
                    />
                  ),
                }}
              />

              <Row>
                <Col sm={9} className="col-xxl-7 offset-sm-3">
                  <p>{t("cassavaSection.content")}</p>
                </Col>
              </Row>
            </Col>
            <Col sm={4} className="align-self-center">
              <GatsbyImage
                image={data.cassavaDust.childImageSharp.gatsbyImageData}
                className="d-none d-md-block"
                alt="Cassava flour"
              />
            </Col>
          </Row>
        </Container>
      </LaYucaSection>

      <BenefitsSection>
        <Container>
          <Row className="justify-content-around justify-content-xl-center">
            <Col lg={6} xxl={5} className="order-last order-md-first">
              <GatsbyImage
                image={data.cassavas.childImageSharp.gatsbyImageData}
                css={css`
                  margin-bottom: 2rem;

                  ${mediaQueries.max576} {
                    display: none;
                  }
                `}
                alt="Yuca"
              />
              <p
                css={css`
                  ${mediaQueries.min992} {
                    margin-left: auto;
                    text-align: right;
                    max-width: 61ch;
                  }
                `}
              >
                {t("benefitsSection.content")}
              </p>
            </Col>
            <Col
              sm={5}
              css={css`
                ${mediaQueries.max576} {
                  margin-bottom: 4rem;
                }
              `}
            >
              <SectionTitle
                css={css`
                  text-align: right;
                `}
              >
                {t("benefitsSection.title")}
              </SectionTitle>
              <BenefitsGrid />
            </Col>
          </Row>
        </Container>
      </BenefitsSection>

      <LaPlantaSection>
        <Container
          css={css`
            display: flex;
            justify-content: center;
          `}
        >
          <LaPlantaGrid />
        </Container>
      </LaPlantaSection>

      <ContactSection />
    </Layout>
  )
}

interface ExtendedColProps extends ColProps {
  xxl?: ColProps["xl"]
}

function Col(colProps: ExtendedColProps): JSX.Element {
  return <BsCol {...colProps} />
}

export default IndexPage

export const query = graphql`
  query HomePage($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    amazonProducts: allDatoCmsAmazonProduct(
      sort: { fields: position }
      filter: { locale: { eq: $language } }
    ) {
      nodes {
        id
        name
        subtitle
        slug
        amazonLink
        photo {
          gatsbyImageData(width: 320)
        }
      }
    }
    recipes: allDatoCmsRecipe(
      limit: 3
      sort: { order: DESC, fields: meta___publishedAt }
      filter: { locale: { eq: $language } }
    ) {
      nodes {
        id
        slug
        mainPhoto {
          gatsbyImageData
        }
        tagline
        title
        description
      }
    }
    heroData: datoCmsHome(locale: { eq: $language }) {
      title
      subtitle
      heroPhotos {
        originalId
        gatsbyImageData(width: 1920)
      }
    }
    homepage: datoCmsHome(locale: { eq: $language }) {
      homepageVideo {
        providerUid
      }
    }
    adBanner: datoCmsHome(locale: { eq: $language }) {
      text: adBannerText
      buttonText: adBannerButtonText
      buttonLink: adBannerButtonLink
      background: adBannerBackground {
        gatsbyImageData
      }
      backgroundMobile: adBannerBackgroundMobile {
        gatsbyImageData
      }
    }
    panyucaSealMobile: file(relativePath: { eq: "panyuca-seal.png" }) {
      childImageSharp {
        gatsbyImageData(height: 106, quality: 80, layout: FIXED)
      }
    }
    panyucaSeal: file(relativePath: { eq: "panyuca-seal.png" }) {
      childImageSharp {
        gatsbyImageData(width: 151, height: 146, quality: 80, layout: FIXED)
      }
    }
    cassavas: file(relativePath: { eq: "cassavas.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 710, placeholder: BLURRED, layout: CONSTRAINED)
      }
    }
    bannerBg: file(relativePath: { eq: "banner-bg.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    manioc: file(relativePath: { eq: "manioc.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 90, layout: CONSTRAINED)
      }
    }
    traditionBg: file(relativePath: { eq: "tradition-bg.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
      }
    }
    traditionBgMobile: file(relativePath: { eq: "tradition-bg-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 576, layout: CONSTRAINED)
      }
    }
    cassavaDust: file(relativePath: { eq: "cassava-dust.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 400, quality: 90, layout: FULL_WIDTH)
      }
    }
    cassavaHorizontal: file(relativePath: { eq: "cassava-horizontal.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 857, layout: CONSTRAINED)
      }
    }
    benefitGlutenFree: file(relativePath: { eq: "benefit-gluten-free.png" }) {
      childImageSharp {
        gatsbyImageData(width: 152, layout: FIXED)
      }
    }
    benefitPaleo: file(relativePath: { eq: "benefit-paleo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 152, layout: FIXED)
      }
    }
    benefitVegan: file(relativePath: { eq: "benefit-vegan.png" }) {
      childImageSharp {
        gatsbyImageData(width: 152, layout: FIXED)
      }
    }
    benefitNoGMO: file(relativePath: { eq: "benefit-no-gmo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 152, layout: FIXED)
      }
    }
    benefitHighFiber: file(relativePath: { eq: "benefit-high-fiber.png" }) {
      childImageSharp {
        gatsbyImageData(width: 152, layout: FIXED)
      }
    }
    benefitLowCalories: file(relativePath: { eq: "benefit-low-calories.png" }) {
      childImageSharp {
        gatsbyImageData(width: 152, layout: FIXED)
      }
    }
    manihot: file(relativePath: { eq: "manihot.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 469, layout: CONSTRAINED)
      }
    }
    casabiTriangle: file(relativePath: { eq: "casabi-triangle.png" }) {
      childImageSharp {
        gatsbyImageData(width: 273, quality: 100, layout: CONSTRAINED)
      }
    }
    casabiGalletas: file(relativePath: { eq: "casabi-galletas.png" }) {
      childImageSharp {
        gatsbyImageData(width: 435, quality: 100, layout: CONSTRAINED)
      }
    }
    casabiStack: file(relativePath: { eq: "casabi-stack.png" }) {
      childImageSharp {
        gatsbyImageData(width: 272, quality: 100, layout: CONSTRAINED)
      }
    }
  }
`
